import {useState} from "react";

const useCarNumber = (defaultValue = "") => {
    const [name, setName] = useState(defaultValue || "")
    const disabled = name.match(/\d/gm)?.length < 3 || !name.match(/\d/gm)?.length

    const handleName = (e) => setName(e.target.value)

    return [name, setName, handleName, disabled]
}
export default useCarNumber