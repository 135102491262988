export const lerp = (min, max, value) => ((1 - value) * min + value * max);
export const drop = items => {
    const total = items.reduce((accumulator, item) => (accumulator += item.chance), 0);
    const chance = lerp(0, total, Math.random());

    let current = 0;
    for (const item of items) {
        if (current <= chance && chance < current + item.chance) {
            return item;
        }

        current += item.chance;
    }
};