import React, {createContext, useContext, useEffect, useState} from 'react';
import ProfileDataItem from "../ProfileDataItem";
import {ProfileContext} from "../../App";
import API from "../../http/requests";
import EditorWrapper from "../../EditorWrapper";
import useCarNumber from "../../hooks/useCarNumber";
import InputMask from "react-input-mask";

export const EditorContext = createContext({})
const ProfileEditor = () => {
    const [userData, setUserData] = useState({
        username: "",
        car_number: ""
    })
    const {changeIsEditing, setProfileData, profileData} = useContext(ProfileContext)
    const disabled = userData.username.length < 1 || userData.car_number?.length < 3 || userData.car_number?.match(/\d/gm)?.length < 3 || !userData.car_number.match(/\d/gm)?.length

    const [carTypes] = useState([
        'Легковые',
        'Внедорожники',
        'Газель-Ford-Iveko',
        'Микроавтобус'
    ])

    const changeProfileDataEdit = (field, val) => {
        setUserData(prev => ({
            ...prev,
            [field]: val
        }))
    }

    const sendProfileDataChanged = () => {
        API.sendChangesProfile(userData).then(({data}) => {
            //console.log(data)
            changeIsEditing()
            //setUserData(data)
            setProfileData(data)
        })
    }
    // useEffect(() => {
    //     console.log(userData)
    // }, [userData])
    useEffect(() => {
        if(profileData?.username) {
            setUserData({...profileData})
        }
    }, [profileData])
    return (
        <EditorContext.Provider value={{userData, carTypes, changeProfileDataEdit}}>
            <EditorWrapper handleModal={changeIsEditing}>
                <div className="profile-content__block" onClick={event => event.stopPropagation()}>
                    <h2>Изменить личные данные</h2>
                    <div className="profile-content__data-col">
                        <ProfileDataItem  field={"username"} fieldName={"Имя"} initialField={profileData.username}/>
                        <ProfileDataItem isCar={true} field={"car_number"} fieldName={"Авто-гос"}
                                         initialField={profileData.car_number}/>

                        <ProfileDataItem isSelect={true} field={"car_type"} fieldName={"Вид авто"}
                                         initialField={profileData.car_type}/>
                    </div>
                    <button onClick={sendProfileDataChanged} disabled={disabled} className="profile-data__btn">Сохранить</button>
                </div>
            </EditorWrapper>
        </EditorContext.Provider>

    );
};

export default ProfileEditor;