import React, {useState} from "react";
import ServicesItem from "./ServicesItem";

export default function ServicesList ({tbFirst, tbSecond, tbThird, tbFourth}) {
    const [list, setList] = useState([
        {
            id: 1,
            opened: false,
            img: 'auto'
        },
        {
            id: 2,
            opened: false,
            img: 'auto2'
        },
        {
            id: 3,
            opened: false,
            img: 'auto3'
        },
        {
            id: 4,
            opened: false,
            img: 'auto4'
        }
    ]);
    const changeListOpened = (index) => {
        if(!list[index].opened) {
            setList(list.map((item)=> {
                item.opened = false;
            }))
        }
        list[index].opened = !list[index].opened
        setList([...list])
    }
    return (
        <ul >
            {
                list.map((item, index) => (
                    <ServicesItem tbFirst={tbFirst} tbSec={tbSecond} tbThird={tbThird} tbFourth={tbFourth} img={item.img} key={index} list={list} opened={item.opened} id={index} onOpened={(i) => {
                        changeListOpened(i)
                    }}/>
                ))

            }

        </ul>
    )
}