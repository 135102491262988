import './Styles.scss'
import {useContext, useEffect, useState} from "react";

import StaticPath from "../../utils/StaticPath";
import {AuthContext} from "../../App";

import React from 'react';

const Roulette = ({prizeItem, handlePrize, title, rltDisable, handleRltModal, options}) => {
    const ctx = useContext(AuthContext)
    const [rouletteActive, rouletteSet] = useState(false)

    const rltSet = () => {
        if (!rouletteActive) rouletteSet(!rouletteActive)
    }

    //console.log(`${options} - items`)


    useEffect(() => {


        // //const prize = prizeDrop()
        // console.log(prize, 'приз')
        // //console.log(options[prize], 'текущий')
        // handlePrize(prize)
    }, [])

    //console.log(prize, 'drop')
    const isMobile = window.screen.width < 460
    const setWidthCanvas = () => {
        if (window.screen.width >= 826) {
            return true
        } else {
            if (window.screen.width < 826) {
                return false
            }
        }
    }
    const isTouch = setWidthCanvas()

    if (rouletteActive) {
        const canvas = document.getElementById('canvas')
        const ctx = canvas.getContext('2d')
        const roulette = {
            prize: prizeItem,
            enable: true,
            x: 0,
            y: 0,
            width: canvas.offsetWidth,
            center: canvas.offsetWidth / 2,
            height: canvas.offsetHeight,
            speed: 15,
            slowDown: 0.45,
            background: '#0E0E0E',
            draw() {
                ctx.fillStyle = this.background;
                ctx.fillRect(this.x, this.y, this.width, this.height);
            },
            log() {
                console.log('speed: ', this.speed)
                console.log('slowDown:', this.slowDown)
            }
        }

        function Gift(x, backColor, src) {
            this.x = x;
            this.y = 0;
            this.width = roulette.width / 3;
            this.center = this.width / 2;
            this.height = roulette.height;
            this.img = backColor;
            this.draw = () => {
                this.img.src = src
                ctx.drawImage(this.img, this.x, this.y, this.width, this.height);
            }
        }

        const settings = {
            startX: -((roulette.width / 3) / 2),
            width: roulette.width / 3,
            opts: [...options],
        }
        let giftItems = settings.opts.map((item, index) => {
            if (index === 0) {
                return new Gift(settings.startX, new Image(), StaticPath('rouletIcons/' + item.src))
            } else if (index === 1) {
                return new Gift(settings.width, new Image(), StaticPath('rouletIcons/' + item.src))
            } else {
                return new Gift((settings.width * index) + (-settings.startX * (index - 1)), new Image(), StaticPath('rouletIcons/' + item.src))
            }
        })

        function rouletteRender() {
            roulette.draw()
            if (roulette.slowDown > 0) {
                roulette.slowDown -= 0.0002
            }
            giftItems.forEach((item, index) => {
                item.draw()
                const isActive = roulette.center > item.x + item.center - item.center / 6 && roulette.center <= item.x + item.center + item.center / 3
                if (item.x + item.width <= 0) {
                    item.x = roulette.width + item.center
                }
                item.x -= roulette.speed
                if (roulette.speed > 0 && index === roulette.prize && isActive) {
                    if (roulette.speed - roulette.slowDown < 0) {
                        roulette.speed = 0
                    } else {
                        roulette.speed -= roulette.slowDown
                    }
                }
            })
        }

        setInterval(() => {
            if (roulette.enable) {
                if (roulette.speed === 0) {
                    roulette.enable = false
                    rltSet()

                    handleRltModal()
                    rltDisable()

                } else {
                    rouletteRender()
                }
            }
        }, 1000 / 50)
    }
    if(ctx.is_auth) {
        if(!ctx.active_roulette) {
            return (
                <div id="roulette">
                    <p style={{textAlign: "center", marginTop: 100}}>
                        Рулетку можно крутить раз в 7 дней!
                    </p>

                </div>
            )
        }
        return (
            <div id="roulette" className="roulette" onClick={rltSet}>
                <div className="polygon__wrapper">
                    <img src={StaticPath("icons/polygon.svg")} className="roulette_polygon" alt=""/>
                </div>
                <div className="roulette__wrapper">
                    {
                        isMobile ?
                            <canvas id="canvas" height={window.screen.width / 3 - 30} width={window.screen.width - 40}>

                            </canvas> :
                            <canvas id="canvas" width={!isTouch ? window.screen.width - 90 : 720}>

                            </canvas>
                    }
                    {
                        !rouletteActive ? <div className="roulette_title">
                            {title}
                        </div> : null
                    }
                </div>
            </div>
        )
    }
    return (
        <div id="roulette">
            <p style={{textAlign: "center", marginTop: 100}}>
                Для прокрутки рулетки, вам необходимо <a style={{color: "red"}} href="/login">войти</a>
            </p>

        </div>
    )

};
export default React.memo(Roulette)