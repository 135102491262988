import styles from "./succes.module.scss"
import StaticPath from "../../utils/StaticPath";

export default function SuccessSended({onButton}) {
    return (
        <div onClick={onButton}  className={styles.SuccessModal}>
            <div onClick={(e) => e.stopPropagation()} className={styles.sendedBlock}>
                <div className={styles.successTitle}>
                    <h1>Успешно!</h1>
                    <img src={StaticPath("icons/tick.svg")}/>
                </div>
                <p>Вы записаны на указанное Вами время.</p>
                <button onClick={onButton} className={styles.btn}>
                    Принять
                </button>
				<p style={{textAlign: "center", fontSize: 13}}>Отмена только по SMS</p>
            </div>
        </div>
    )
}