import './Styles.scss'
import StaticPath from "../../utils/StaticPath";
import {Link} from "react-router-dom";

export default function Header({rlt,content}) {
    const logo = content.logo;
    const nav = content.nav[0];
    const callback = content.callback
    return (
        <div className="header">
            <div className="wrapper header-wrapper">
                <div className="header__top-pres">
                    <img src={StaticPath("icons/logo.png")} alt="" className="logo"/>
                    <div>
                        <p>
                            {logo.topTitle}
                        </p>
                        <span>
                        {logo.bottomTitle}
                    </span>
                    </div>
                </div>
                <ul className="header__nav">
                    <li><Link to={"/"}>{nav.main.title}</Link></li>
                    <li><a href="/#services">{nav.services.title}</a></li>
                    <li><a href="/#contacts">{nav.contacts.title}</a></li>
                    <li><a href="/new">Новости</a></li>
                    <li><a href={nav.vk.url}>{nav.vk.title}</a></li>
                    {
                        !rlt ? <li id="fortLink"><a href="#roulette">{nav.fortune.title}</a></li> : null
                    }
                </ul>
                <a href={'tel:'+ callback.phone} className="header__call">
                    {callback.title}
                </a>
            </div>
        </div>
    )
}
