import {useEffect, useState} from "react";
import api from "../../http/api";

import './Styles.scss';
import useCarNumber from "../../hooks/useCarNumber";
import API from "../../http/requests";
import InputMask from 'react-input-mask'

export default function Form({dataForm, openErrorModal, openSuccess}) {
    const [carTypes] = useState([
        'Легковые',
        'Внедорожники',
        'Газель-Ford-Iveko',
        'Микроавтобус',
        'Кроссовер'
    ])

    const [tireTypes] = useState([
        'Не знаю',
        'R13',
        'R14',
        'R15',
        'R16',
        'R17',
        'R18',
        'R19',
        'R20',
        'R21',
    ])
    const [vidStates] = useState([
        "Смена колёс без балансировки",
        "Смена колёс + балансировка (2к-та дисков)",
        "Полная переобувка (один диски + покрышки отдельно)",
        "Ремонт колеса"
    ])

    const [timeActive, setTimeActive] = useState(false);
    const days = dataForm.days;
    const [time, setTime] = useState([]);
    const handleTime = (day) => {
        setTime(days[day].time)
        setDate(days[day].data)
        setTimeActive(true)

    }
    const [date, setDate] = useState();
    const [timeCheck, setTimeCheck] = useState()
    const checkRecord = () => {
        const req = {
            date: date,
            time: timeCheck
        }
        API.checkRecord(req).then(data => {
            if (data.data.status) {
                postForm()
                return 1;
            }
            openErrorModal()
        })
    }

    const [name, _, handleName, disabledName] = useCarNumber('')
    const [phone, setPhone] = useState('')
    const [transp, setTransp] = useState()
    const [tire, setTireType] = useState()
    const [vid, setVid] = useState()
    const [comment, setComment] = useState("")

    const disabled = phone.length < 11 || disabledName

    const postForm = () => {
        const req = {
            name: name,
            tel: phone,
            transp: transp,
            vid: vid,
            date: date,
            time: timeCheck,
            tire: tire,
            comment: comment
        }
        api.post('/records', req)
            .then(res => {
                console.log(res.data.status);
                if (res.data.status) {
                    openSuccess()
                }
            })
    }

    return (
        <form onSubmit={(e) => {
            e.preventDefault();
            console.log('submit')
            checkRecord()
        }} id="form" action="" onClick={(e) => {
            e.stopPropagation()
        }}>
            <h2>Записаться</h2>
            <div className="form__wrapper">
                <input type="text" onChange={handleName} value={name} placeholder="Гос. номер авто (е223кх 35)"/>
                {/*<InputMask onChange={handleName} value={name} formatChars={{*/}
                {/*    '9': '[0-9]',*/}
                {/*    'a': '[а-я]'*/}
                {/*}} mask="a999aa 999" maskChar="" placeholder="Гос. номер авто (е223кх 35)" />*/}
                <InputMask onChange={(e) => setPhone(e.target.value)} value={phone} formatChars={{
                    '9': '[0-9]',
                    'a': '[а-я]'
                }} mask="99999999999" maskChar="" placeholder="Введите номер в федеральном формате!" />
                {/*<input onInput={e => e.target.setCustomValidity('')}*/}

                {/*       onInvalid={e => e.target.setCustomValidity('Введите номер в федеральном формате!')}*/}
                {/*       maxLength={11} onChange={} required name="tel" type="text"*/}
                {/*       placeholder="Номер телефона"/>*/}

                <select onChange={(e) => setTransp(e.target.value)} required name="transp" id="">
                    <option value="" disabled selected hidden>Вид транспорта</option>
                    {
                        carTypes.map(item => (
                            <option value={item}>{item}</option>
                        ))
                    }
                </select>
                <select onChange={(e) => setTireType(e.target.value)} required name="tire" id="">
                    <option value="" disabled selected hidden>Радиус колеса</option>
                    {
                        tireTypes.map(item => (
                            <option value={item}>{item}</option>
                        ))
                    }
                </select>

                <select onChange={(e) => setVid(e.target.value)} required name="vid" id="">
                    <option value="" disabled selected hidden>Выберите тип работ</option>
                    {vidStates.map(item => (
                        <option value={item}>{item}</option>
                    ))}
                </select>
                <select name="date" required onChange={(e) => {
                    handleTime(days.findIndex(i => i.data === e.target.value))
                }} id="">
                    <option value="" disabled selected hidden>Выберите день</option>
                    {
                        days.map((item, index) => (
                                <option key={index} onClick={() => handleTime(index)} value={item.data}>{item.data}</option>
                            )
                        )
                    }
                </select>
                <select onChange={(e) => setTimeCheck(e.target.value)} name="time" required id=""
                        disabled={!timeActive}>
                    <option value="" disabled selected hidden>Время</option>
                    {
                        time.length > 0 ?
                            time.map((item) => (
                                <option value={item}>{item}</option>
                            ))
                            : null
                    }

                </select>
                <textarea value={comment} onChange={event => setComment(event.target.value)} placeholder="Комментарий (необязательно)" name="comment"></textarea>
                <input disabled={disabled} value={'Записаться'} readOnly className="buttonSubmit"
                       type="submit"/>
            </div>
        </form>
    )
}