import api from "./api";

export default class API {
    static async getBase(cb) {
        const {data} = await api.get('/base')
        cb(data)
    }

    static async getAuth() {
        const {data} = await api.get('/is-auth')
        return {
            auth: data?.auth,
            roulette: data?.activ_roul
        }
    }

    static async getPrizes(cb) {
        const {data} = await api.get('/get-bonus')
        cb(data)
    }
    static async checkRecord(data) {
        return {data} = await api.post('/check_records', {...data})
    }
    static async sendGift(id) {
        return await api.get(`/giv-bonus/${id}`)
    }

    static async getProfile() {
        const {data} = await api.get('/get_data_lk')
        return data;
    }
    static async sendChangesProfile(data) {
        //console.log({...data})
        return await api.post('/edit_profile', data)
    }
    static async changePhone(data, type = 1) {
        return await api.post(`/edit-number/${type}`, {...data})
    }
    static async sendCode(data, type = 1) {
        return await api.post(`/check-number/${type}`, {...data})
    }
    static async getNews() {
        return await api.get(`/news`)
    }
}