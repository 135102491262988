import './index.scss';
import React, {createContext, memo, useEffect, useMemo, useState} from 'react'
import Header from "./components/Header/Header";
import HeaderMobile from "./components/Header/HeaderMobile";
import Services from "./components/Services/Services";
import ServicesList from "./components/ServicesList/ServicesList";
import Footer from "./components/Footer/Footer";
import Roulette from "./components/Roulette/Roulette";
import ModalRoulette from "./components/Roulette/ModalRoulette";
import Preloader from "./components/Preloader/Preloader";
import SuccessSended from "./components/SuccessSended";
import Form from "./components/Form/Form";
import ErrorForm from './components/Form/ErrorForm'
import StaticPath from "./utils/StaticPath";
import {getCookie, setCookie} from "./utils/Cooker";
import API from "./http/requests";
import {diff_hours} from "./utils/DiffHours";

import ProfileEditor from "./components/ProfileEditor";
import PhoneEditor from "./components/PhoneEditor";
import {drop} from "./utils/Randomer";
import AppRoutes from "./components/AppRoutes";
import New from "./components/New";
import Api from "./http/api";

export const AuthContext = createContext({is_auth: false, active_roulette: false})
export const ContentContext = createContext({})
export const ProfileContext = createContext({})

const prizeDrop = (options = []) => {
    let indexItem = null;
    //console.log(options)
    const prizeDropped = drop(options)
    //console.log(prizeDropped)
    //console.log(prizeDropped.id, 'dropped')
    options.forEach((item, index) => {
        //console.log(item)
        // console.log(index)
        // console.log(prizeDropped.id)
        // console.log(item.id, prizeDropped.id)

        //console.log(indexItem)
        if (indexItem === null) {
            if (item.id === prizeDropped.id) {
                //console.log(prizeDropped.title, 'prizik')
                indexItem = index
                // console.log(prizeDropped.id, index)
                //console.log(indexItem)

            }
        }

    })

    //console.log(indexItem)
    // if (indexItem === undefined) {
    //     console.log('ass')
    //     indexItem = options.findIndex(e => e.empty === true)
    // }
    return indexItem
}

const getTimeRoulette = () => {
    let timeRoulette;
    if (getCookie('hrsRlt')) {
        timeRoulette = (diff_hours(new Date(getCookie('hrsRlt'))))
    }
    return timeRoulette
}
const touchScreen = window.screen.width < 839;

function App() {
    const [content, setContent] = useState()
    const [contentPrizes, setContentPrizes] = useState([])
    const [auth, setAuth] = useState(false)
    const [activeRoulette, setActiveRoulette] = useState(false)
    const [openForm, setForm] = useState(false)
    const [formError, setFormError] = useState(false);
    const [formSended, setFormSended] = useState(false)

    const [profileData, setProfileData] = useState({})
    const [bonuses, setBonuses] = useState([])

    const handleFormSended = () => {
        setFormSended(!formSended);
        //console.log("sas")
    }

    const handleFormError = () => {
        setFormError(!formError);
    }

    const handleFormOpen = () => {
        setForm(!openForm)
    }

    useEffect(() => {
        (async () => {
            await API.getBase(data => setContent(data))
                .then(() => {
                    API.getAuth().then(res => {
                        setAuth(res.auth)
                        setActiveRoulette(res.roulette)
                    })
                })
                .then(() => {
                    API.getPrizes(data => setContentPrizes(data.bonus))
                }).then(() => {
                    API.getProfile().then((res) => {
                        setProfileData(res.user)
                        setBonuses(res.bonus)
                        //console.log(res)
                    })
                })
        })()
        //console.log(contentPrizes[prizeItem])
    }, [])


    const [prizeItem, setPrizeItem] = useState()

    const handlePrizeItem = (val) => {
        setPrizeItem(val)
    }

    useEffect(() => {
        handlePrizeItem(prizeDrop(content?.roulette?.roulette_data?.items))
        //console.log(prizeItem)
    }, [content?.roulette?.roulette_data.items])


    const [menuOpened, setMenuOpened] = useState(false)
    const closeMenu = () => setMenuOpened(false)
    const handleMenuOpen = () => setMenuOpened(!menuOpened)
    const [rltIsUsed, setUsed] = useState(getCookie('fRedOmStudiOteAms'))
    const timeRoulette = useMemo(getTimeRoulette, [rltIsUsed])
    const rltUse = () => {

        const d = new Date();
        if (!contentPrizes[prizeItem].empty) {
            API.sendGift(contentPrizes[prizeItem].id).then()
        }
        d.setTime(d.getTime() + (30 * 24 * 60 * 60 * 1000));
        setCookie('fRedOmStudiOteAms', "dj8fksXfi8lkfdXs", 30)
        setCookie('hrsRlt', d, 30)
        setUsed(true)
    }
    const [rouletteModal, setRltModal] = useState(false)

    const handleRltModal = () => {
        setRltModal(!rouletteModal)
    }
    const handleCloseFormSended = () => {
        handleFormSended()
        window.location.reload()
    }


    const changePrizeItem = (val) => handlePrizeItem(val)
    const MainPage = memo(() => {

        return (
            <>
                {
                    content ?
                        <div className="App">
                            <a href="/profile" className="btn__profile-link">
                                <img title="Личный кабинет" className="btn__profile-img"
                                     src={StaticPath('icons/profile.svg')} alt=""/>
                            </a>
                            {
                                formSended ?
                                    <SuccessSended onButton={handleCloseFormSended}/> :
                                    null
                            }
                            {
                                rouletteModal ?
                                    <>
                                        <ModalRoulette promo={prizeItem ? contentPrizes[prizeItem].promo : null}
                                                       modalText={content.roulette.roulette_data.modal}
                                                       itemPrize={prizeItem}
                                                       rData={contentPrizes}
                                                       title={prizeItem ? contentPrizes[prizeItem].title : null}
                                                       modalDisable={handleRltModal}/>
                                    </>
                                    : null
                            }
                            <div onClick={closeMenu}>
                                <div className="main__top">
                                    {
                                        touchScreen ? <HeaderMobile content={content.header}
                                                                    rlt={rltIsUsed} stateMenu={menuOpened}
                                                                    menuOpen={handleMenuOpen}/> :
                                            <Header rlt={rltIsUsed} content={content.header}/>
                                    }
                                    <div className="welcome">
                                        <div className="wrapper">
                                            <div className="welcome__text">
                                                <p>
                                                    {content.top.welcomeText}
                                                </p>
                                                <h1>
                                                    {content.top.welcomeTitle}
                                                </h1>
                                            </div>
                                            <div className="welcome__features">
                                                <div>
                                                    <img src={StaticPath('icons/prices.svg')} alt=""/>
                                                    <span>{content.top.features.first}</span>
                                                </div>
                                                <div>
                                                    <img src={StaticPath('icons/work.svg')} alt=""/>
                                                    <span>{content.top.features.second}</span>
                                                </div>
                                                <div>
                                                    <img src={StaticPath('icons/timelines.svg')} alt=""/>
                                                    <span>{content.top.features.third}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="main__services">
                                    <div className="wrapper main--wrapper">
                                        <div className="services__info">
                                            <div className="services__list">
                                                {content.listFeatures.map((item, index) => (
                                                    <Services key={index} title={item.title} text={item.deskrip}/>
                                                ))}
                                            </div>
                                            <img className="services__img" src={StaticPath("services_photo.png")}
                                                 alt=""/>
                                        </div>
                                        <div className="services__main-list" id="services">
                                            <div className="main__list-title">
                                                <h1>{content.title}</h1>
                                                <hr/>
                                            </div>
                                            <ServicesList tbFirst={content.tableFirst} tbSecond={content.tableSecond}
                                                          tbThird={content.tableThird} tbFourth={content.table_fourth}/>
                                        </div>
                                        {touchScreen ?
                                            <Form openSuccess={handleFormSended} openErrorModal={handleFormError}
                                                  dataForm={content.applicat}/> :
                                            <div className={"formOrderWrapper"}>
                                                <div onClick={handleFormOpen} className="formOrder">Записаться на
                                                    переобувку</div>
                                                <div className={"formOrderCaption"}>
                                                    Для получения оповещений и смс кодов, необходимо предварительно отправить номер телефона в чат бота,
                                                    в <a target="_blank" href="https://vk.com/city35cher">ВКонтакте</a> или в <a target="_blank" href="https://t.me/nagogolya_bot">Телеграмм</a>

                                                </div>
                                            </div>}

                                        {
                                            !rltIsUsed ?
                                                <Roulette prizeItem={prizeItem} handlePrize={changePrizeItem}
                                                          title={content.roulette.title}
                                                          options={content.roulette.roulette_data.items}
                                                          handleRltModal={handleRltModal}
                                                          rltDisable={rltUse}/> :
                                                <h2 className="hoursTry">До следующей попытки: {timeRoulette[0]} дней
                                                    и {timeRoulette[1]} часов</h2>
                                        }
                                    </div>
                                </div>
                                {
                                    !touchScreen && openForm ?
                                        <div className="modal" onClick={handleFormOpen}>
                                            <div className="modal-body">
                                                <Form openSuccess={handleFormSended} openErrorModal={handleFormError}
                                                      dataForm={content.applicat}/>
                                            </div>
                                        </div> : null
                                }

                                {
                                    formError ?
                                        <div className="modal modalError" onClick={() => {
                                            handleFormError();
                                            window.location.reload()
                                        }}>
                                            <div className="modal-body">
                                                <ErrorForm openErrorModal={handleFormError}/>
                                            </div>
                                        </div> : null
                                }

                                <Footer content={content.footer}/>
                            </div>
                        </div>
                        :
                        <Preloader/>
                }
            </>

        )
    })

    const Profile = memo(() => {
        const [isEditing, setIsEdigiting] = useState(false)
        const [phoneIsEditing, setPhoneIsEditing] = useState(false)

        const changeIsEditing = () => {
            setIsEdigiting(!isEditing)
        }

        const changeIsPhoneEditing = () => {
            setPhoneIsEditing(!phoneIsEditing)
        }

        useEffect(() => {
            console.log('sas')

        }, [])

        return (

            <ProfileContext.Provider value={{changeIsEditing, setProfileData, changeIsPhoneEditing, profileData}}> {
                content ?
                    <div className="App">

                        {
                            formSended ?
                                <SuccessSended onButton={handleCloseFormSended()}/> :
                                null
                        }
                        <div onClick={() => setMenuOpened(false)}>
                            <div className="main__top main__profile">
                                {
                                    touchScreen ?
                                        <HeaderMobile formHandle={() => handleFormOpen()} content={content.header}
                                                      rlt={rltIsUsed} stateMenu={menuOpened}
                                                      menuOpen={handleMenuOpen}/> :
                                        <Header formHandle={() => handleFormOpen()} rlt={rltIsUsed}
                                                content={content.header}/>
                                }
                                {
                                    isEditing ?
                                        <ProfileEditor/>
                                        :
                                        null
                                }

                                {
                                    phoneIsEditing ?
                                        <PhoneEditor/> :
                                        null
                                }
                                <div className="profile-content__blocks">
                                    <div className="profile-content__block">
                                        <h2>Личные данные</h2>
                                        <div className="profile-content__data-col">
                                            <div className="profile-data__item">
                                                <p>Имя: <span>{profileData.username}</span></p>
                                            </div>
                                            <div className="profile-data__item">
                                                <p>Номер: <span>{profileData.phone_number}</span></p>
                                            </div>
                                            <div className="profile-data__item">
                                                <p>Авто-гос: <span>{profileData.car_number}</span></p>
                                            </div>
                                            <div className="profile-data__item">
                                                <p>Вид авто: <span>{profileData.car_type}</span></p>
                                            </div>
                                            <div className="profile-data__item">
                                                <p>Кол-во баллов: <span>{profileData.points}</span></p>
                                            </div>
                                        </div>
                                        <button onClick={changeIsEditing} className="profile-data__btn">Изменить
                                        </button>
                                    </div>

                                    <div className="profile-content__block">
                                        <h2>Бонусы ({bonuses.length})</h2>
                                        <div className="profile-content__data-col">
                                            {
                                                bonuses.map((item) => (
                                                    <div className="profile-data__item">
                                                        <p>{item.title}: <span>{item.promo}</span></p>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                        <div className="profile-data__btn"><a href="/#roulette">Крутить Рулетку</a>
                                        </div>
                                    </div>
                                    <div className="profile-content__block">
                                        <h2>Телефон</h2>
                                        <div className="profile-content__data-col">
                                            <div className="profile-data__item">
                                                <p>Номер: <span>{profileData.phone_number}</span></p>
                                            </div>
                                        </div>
                                        <div className="profile__btns">

                                        </div>
                                        <button onClick={changeIsPhoneEditing} className="profile-data__btn">Изменить
                                        </button>
                                    </div>
                                    <div className="profile-content__block">
                                        <h2>Аккаунт</h2>
                                        <div className="profile-content__data-col">
                                            <a style={{color: "red"}} href="/logout">Выйти из аккаунта</a>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            {
                                !touchScreen && openForm ?
                                    <div className="modal" onClick={handleFormOpen}>
                                        <div className="modal-body">
                                            <Form openSuccess={handleFormSended} openErrorModal={handleFormError}
                                                  dataForm={content.applicat}/>
                                        </div>
                                    </div> : null
                            }

                            {
                                formError ?
                                    <div className="modal modalError" onClick={() => {
                                        handleFormError();
                                        window.location.reload()
                                    }}>
                                        <div className="modal-body">
                                            <ErrorForm openErrorModal={handleFormError}/>
                                        </div>
                                    </div> : null
                            }

                            <Footer content={content.footer}/>
                        </div>
                    </div>
                    :
                    <Preloader/>
            }
            </ProfileContext.Provider>
        )
    })
    const News = memo(() => {
        const [news, setNews]= useState([]);
        useEffect(() => {
            API.getNews().then(res => {
                setNews(res.data.news)
            })
        }, [])
        return (

            <> {
                content ?
                    <div className="App">

                        {
                            formSended ?
                                <SuccessSended onButton={handleCloseFormSended()}/> :
                                null
                        }
                        <div onClick={() => setMenuOpened(false)}>
                            <div className="main__top main__news">
                                {
                                    touchScreen ?
                                        <HeaderMobile formHandle={() => handleFormOpen()} content={content.header}
                                                      rlt={rltIsUsed} stateMenu={menuOpened}
                                                      menuOpen={handleMenuOpen}/> :
                                        <Header formHandle={() => handleFormOpen()} rlt={rltIsUsed}
                                                content={content.header}/>
                                }


                                <div className="news">
                                    <h2 className="news__title">Новости</h2>
                                    <div className="news__list">

                                        {
                                            news.length ?
                                            news.map(item => (
                                                <New img={item.image} date={item.date} key={item.id} title={item.name} text={item.text}/>
                                            )) :
                                                null
                                        }

                                    </div>
                                </div>
                            </div>
                            {
                                !touchScreen && openForm ?
                                    <div className="modal" onClick={handleFormOpen}>
                                        <div className="modal-body">
                                            <Form openSuccess={handleFormSended} openErrorModal={handleFormError}
                                                  dataForm={content.applicat}/>
                                        </div>
                                    </div> : null
                            }

                            {
                                formError ?
                                    <div className="modal modalError" onClick={() => {
                                        handleFormError();
                                        window.location.reload()
                                    }}>
                                        <div className="modal-body">
                                            <ErrorForm openErrorModal={handleFormError}/>
                                        </div>
                                    </div> : null
                            }

                            <Footer content={content.footer}/>
                        </div>
                    </div>
                    :
                    <Preloader/>
            }
            </>
        )
    })

    const AuthApp = memo(() => {
        return (
            <AuthContext.Provider value={{is_auth: auth, active_roulette: activeRoulette}}>
                <AppRoutes Profile={Profile} MainPage={MainPage} News={News}/>
            </AuthContext.Provider>
        )
    })
    return (
        <ContentContext.Provider value={content}>
            <AuthApp/>
        </ContentContext.Provider>


    );

}

export default App;
