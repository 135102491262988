import './Styles.scss'
import StaticPath from "../../utils/StaticPath";
export default function Footer ({content}) {
    const srcPathImage = 'icons/';
    const contacts = [
        {
            src: "phone.svg",
            link: "tel:" + content.contacts[0].link
        },
        {
            src: "clock.svg",
        },
        {
            src: "mail.svg",
            link: content.contacts[2].link
        }
    ]
    return (
        <div className="footer" id="contacts">
            <div className="wrapper">
                <div className="footer__blocks">
                    <div className="contacts">
                        {
                            content.contacts.map((item, index)=> (
                                <div key={index} className="contacts__item">
                                    <img src={StaticPath(srcPathImage + contacts[index].src)} alt=""/>
                                    <div>
                                        <h3>
                                            {item.title}
                                        </h3>
                                        {
                                            item.link ? <a href={contacts[index].link}>{item.text}</a> :
                                                <p>{item.text}</p>
                                        }
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                    <div className="geo">
                        <img src={StaticPath("icons/geo.svg")} alt=""/>
                        <div className="geo__block">
                            <iframe
                                src="https://yandex.ru/map-widget/v1/?um=constructor%3A0a517f0ea3b74d0c10647a2ac250d879d477398fb285001627e49ebd2376849f&amp;source=constructor"
                                width="625" height="350" frameBorder="0">
                            </iframe>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}