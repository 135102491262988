import React, {useContext, useEffect, useState} from 'react';
import {EditorContext} from "../ProfileEditor";
import InputMask from "react-input-mask";

const ProfileDataItem = ({onHandle = null, maxLength = null,isCar = false, isSelect = false, initialField, fieldName, field}) => {
    const [value, setValue] = useState(initialField || "")
    const {changeProfileDataEdit, carTypes} = useContext(EditorContext)
    //console.log(value)

    useEffect(() => {
        setValue(initialField)
    }, [initialField])
    const changeField = (val) => {
        changeProfileDataEdit(field, val)
        setValue(val)
    }
    return (
        <div className="profile-data__item">
            <div className="p-f">
                <p>{fieldName}: </p>
                {
                    isSelect ?
                        <select onChange={e => changeField(e.target.value)} >
                            <option style={{display: "none"}} selected={true} disabled={true} value="">{initialField}</option>
                            {
                                carTypes.map(item => (
                                    <option value={item}>{item}</option>
                                ))
                            }
                        </select> :
                        isCar ?
                            // <InputMask onChange={e => changeField(e.target.value)} value={value} formatChars={{
                            //     '9': '[0-9]',
                            //     'a': '[а-я]'
                            // }} mask="a999aa 999" maskChar="" placeholder="Г.номер (е223кх 35)" />
                            <input type="text" value={value} onChange={e => changeField(e.target.value)}/>
                            :
                        <input maxLength={maxLength}  type="text" value={value} onChange={e => changeField(e.target.value)}/>
                }

                <span>  </span>
            </div>
        </div>
    );
};

export default ProfileDataItem;