import React from "react";
import './Styles.scss'
import StaticPath from "../../utils/StaticPath";
import {Link} from "react-router-dom";
export default function HeaderMobile({rlt,content,menuOpen, stateMenu}) {
    const logo = content.logo;
    const nav = content.nav[0];
    const callback = content.callback
    console.log(logo)
    return (
        <div className="header header--mobile" onClick={event => event.stopPropagation()}>
            <div className="wrapper header-wrapper">
                <div className="header__top-pres">
                    <img src={StaticPath("icons/logo.png")} alt="" className="logo-mobile"/>
                    <div >
                        <p>
                            {logo.topTitle}
                        </p>
                        <span>
                        {logo.bottomTitle}
                        </span>
                    </div>
                </div>
                <svg onClick={menuOpen} id="burger" xmlns="http://www.w3.org/2000/svg" width={40} height={40} fill="#B9191B"
                     className="bi bi-justify" viewBox="0 0 16 16">
                    <path fill-rule="evenodd"
                          d="M2 12.5a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z"/>
                </svg>
            </div>
            {
               stateMenu ?
                    <div className="header__menu" >
                        <ul>
                            <li><a href="/">{nav.main.title}</a></li>
                            <li><a href="/#services">{nav.services.title}</a></li>
                            <li><a href="#contacts">{nav.contacts.title}</a></li>
                            <li><a href={nav.vk.url}>{nav.vk.title}</a></li>
                            <li><a href="/profile">Личный кабинет</a></li>
                            <li><a href="/#form">Запись на монтаж</a></li>
                            <li><a href="/new">Новости</a></li>
                            {
                                !rlt ? <li id="fortLink"><a id href="#roulette">{nav.fortune.title}</a></li> : null
                            }
                            <li><a href={'tel:'+ callback.phone}>{callback.title}</a></li>
                        </ul>
                    </div> :
                    null

            }

        </div>
    )
}
