import './Styles.scss';
export default function ErrorForm ({openErrorModal}) {

    return (
        <div onClick={event => event.stopPropagation()} className="errorForm">
            <h3>
                Запись на это время недоступна.
            </h3>
            <p>Пожалуйста выберите другое время.</p>
            <div className="btnAccess" onClick={()=>{openErrorModal(); window.location.reload()}}>
                Ок
            </div>
        </div>
    )
}