import React, {useContext, useState} from 'react';
import EditorWrapper from "../../EditorWrapper";
import {ProfileContext} from "../../App";
import {PatternFormat} from 'react-number-format'
import API from "../../http/requests";
import {PhoneInsertSeven} from "../../utils/PhoneInsertSeven";


const PhoneEditor = () => {
    const defaultInputValue = {
        value: '',
        formattedValue: ''
    }
    const {changeIsPhoneEditing, profileData} = useContext(ProfileContext)
    const [phoneOld] = useState({})
    const [codeOld, setCodeOld] = useState(defaultInputValue)
    const [codeNew, setCodeNew] = useState(defaultInputValue)
    const [newPhone, setNewPhone] = useState(defaultInputValue)

    const [oldCodeError, setOldCodeError] = useState(false)
    const [newCodeError, setNewCodeError] = useState(false)
    const [newPhoneError, setNewPhoneError] = useState('')

    const [oldIsAccepted, setOldIsAccepted] = useState(false);
    const [successEdited, setSuccesEdited] = useState(false)

    const sendOldPhone = () => {
        if (profileData.phone_number) {
            API.changePhone().then(({data}) => {
                console.log(data)
                if (data.status) {
                    //setOldCodeDisabled(false)
                }
            }).catch(e => console.log(e))
        }
    }
    const sendNewPhone = () => {
        if (newPhone?.value.length > 0) {

        }
        API.changePhone({phone: PhoneInsertSeven(newPhone.value)}, 2).then(({data}) => {
            console.log(data)
            if (data.status) {
                if(newPhoneError) {
                    setNewPhoneError('')
                }

                return 1
            }
            if(data.user_exist) {
                setNewPhoneError('Данный номер занят!')
            }
        }).catch(e => console.log(e))
    }

    const sendCodeOld = () => {
        let requestsCount = 0;
        const req = () => {
            API.sendCode({code: codeOld.value}).then(({data}) => {
                console.log(data)
                if (data.status) {
                    if (oldCodeError) {
                        setOldCodeError(!oldCodeError)
                    }
                    setOldIsAccepted(true)

                    return 1;
                }
                if (data.error_code) {
                    setOldCodeError(!oldCodeError)
                }
            }).catch(e => {
                if(requestsCount < 3) {
                    req()
                }
            })
            requestsCount += 1
        }
        req()
    }
    const sendCodeNew = () => {
        API.sendCode({code: codeNew.value, phone: PhoneInsertSeven(newPhone.value)}, 2).then(({data}) => {
            console.log(data)
            if (data.status) {
                if(newCodeError) {
                    setNewCodeError(!newCodeError)
                }
                setSuccesEdited(true)
                return 1
            }
            if(data.error_code) {
                setNewCodeError(!newCodeError)
            }
        }).catch(e => console.log(e))
    }
    console.log(codeNew, newPhone)
    return (
        <EditorWrapper handleModal={changeIsPhoneEditing}>
            <div className="profile-content__block profile-content__phone" onClick={event => event.stopPropagation()}>
                <h2>Изменить номер телефона</h2>
                <div className="profile-content__data-col" style={{flexDirection: "column"}}>
                    {
                        !successEdited ?

                            !oldIsAccepted ?
                                <>
                                    <div className="profile-data__item">
                                        <div className="p-f"
                                             style={{flexDirection: "column", alignItems: "unset", gap: 10}}>
                                            <p>Текущий номер телефона </p>
                                            <PatternFormat displayType={'text'} defaultValue={profileData.phone_number}
                                                           format="+# (###) ### ####" allowEmptyFormatting mask="_"
                                                           value={phoneOld.formattedValue}
                                            />
                                        </div>

                                    </div>
                                    <button onClick={sendOldPhone} disabled={!profileData.phone_number}
                                            className="profile-data__btn">Получить код
                                    </button>
                                    <div className="profile-data__item">
                                        <div className="p-f"
                                             style={{flexDirection: "column", alignItems: "unset", gap: 10}}>
                                            <p>Код подтверждения </p>
                                            <PatternFormat format="###-###" allowEmptyFormatting mask="_"
                                                           value={codeOld.formattedValue}
                                                           onValueChange={values => setCodeOld(values)}
                                            />
                                            <p className="phone-data__error">{oldCodeError ? 'Неверный код' : null}</p>
                                        </div>
                                    </div>
                                    <button onClick={sendCodeOld} disabled={codeOld.value.length < 6}
                                            className="profile-data__btn">Подтвердить
                                    </button>
                                </> :
                                <>
                                    <div className="profile-data__item">
                                        <div className="p-f"
                                             style={{flexDirection: "column", alignItems: "unset", gap: 10}}>
                                            <p>Новый номер телефона </p>
                                            <PatternFormat format="+7 (###) ### ####" allowEmptyFormatting mask="_"
                                                           value={newPhone.formattedValue}
                                                           onValueChange={values => {
                                                               setNewPhone(values)
                                                           }}
                                            />
                                            <p className="phone-data__error">{newPhoneError ? newPhoneError : null}</p>
                                        </div>

                                    </div>
                                    <button onClick={sendNewPhone} disabled={newPhone.value.length < 10}
                                            className="profile-data__btn">Получить код
                                    </button>
                                    <div className="profile-data__item">
                                        <div className="p-f"
                                             style={{flexDirection: "column", alignItems: "unset", gap: 10}}>
                                            <p>Код подтверждения </p>
                                            <PatternFormat format="###-###" allowEmptyFormatting mask="_"
                                                           value={codeNew.formattedValue}
                                                           onValueChange={values => setCodeNew(values)}
                                            />
                                            <p className="phone-data__error">{newCodeError ? 'Неверный код' : null}</p>
                                        </div>
                                    </div>
                                    <button onClick={sendCodeNew} disabled={codeNew.value.length < 6}
                                            className="profile-data__btn">Отправить
                                    </button>
                                </> :
                            <div>
                                <h4 style={{color: "green"}}>Телефон изменен!</h4>
                                <p>Необходимо <a style={{color: "red", textDecoration: "underline"}} href="/logout">перезайти в аккаунт</a></p>
                            </div>

                    }

                </div>
            </div>
        </EditorWrapper>
    );
};

export default PhoneEditor;