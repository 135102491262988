import React, {useState} from "react";
import './Styles.scss'
import StaticPath from "../../utils/StaticPath";
export default function Services({title, text}) {
    const [blockOpened, setBlockOpened] = useState(false)
    const handleBlockOpen = () => setBlockOpened(!blockOpened)
    return (
        <div className="services__list_item">
            <img onClick={handleBlockOpen} className="handle__btn-services" width={30} src={blockOpened ? StaticPath("icons/minus.svg") : StaticPath("icons/plus.svg")} alt=""/>
            {
                blockOpened ?
                <div className="list__item-opened slide-in-left">
                    <span>
                        {title}
                    </span>
                    <hr/>
                    <p>
                        {text}
                    </p>
                </div> :
                <p className="list__p">
                    {title}
                </p>
            }
        </div>
    )
}