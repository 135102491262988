import axios from "axios";

const is_dev = 0
const api = axios.create({
    baseURL: is_dev ? "http://dev.advafert.ru" : '/',
    headers: {
        "Access-Control-Allow-Origin": "*"
    }
});


export default api