import './Styles.scss'
import StaticPath from "../../utils/StaticPath";
export default function  ServicesItem({tbFirst, tbSec, tbThird, tbFourth,opened, onOpened, id, img}) {
    const handleTableOpen = (index) => {
        onOpened(index)
    }

    const tableType = () => {
        switch (id) {
            case 0:
                return (
                    <div className="table slide-top">
                        <div className="table__header">
                            {
                                tbFirst.header.map((item, index)=> (
                                    <div key={index}>
                                        <h5>{item.title}</h5>
                                        {item.deskrip ? <span>{item.deskrip}</span> : null}
                                    </div>
                                ))
                            }
                        </div>
                        <div className="table__body">
                            <div className="table__column">
                                {
                                    tbFirst.body.first_column.map((item,index)=> (
                                        <h4 key={index}>{item}</h4>
                                    ))
                                }
                            </div>
                            <div className="table__column">
                                {
                                    tbFirst.body.second_column.map((item,index)=> (
                                        <h4 key={index}>{item}</h4>
                                    ))
                                }
                            </div>
                            <div className="table__column">
                                {
                                    tbFirst.body.third_column.map((item,index)=> (
                                        <h4 key={index}>{item}</h4>
                                    ))
                                }
                            </div>
                            <div className="table__column">
                                {
                                    tbFirst.body.fourth_column.map((item,index)=> (
                                        <h4 key={index}>{item}</h4>
                                    ))
                                }
                            </div>
                        </div>
                        <p style={{marginTop:10, fontSize: 15, textAlign: "center"}}>
                            {tbFirst.Zalupa_kona}
                        </p>
                    </div>
                )
                break;
            case 2:
                return (
                    <div className="table slide-top table-cargo">
                        <div className="table__header">
                            {
                                tbThird.header.map((item, index)=> (
                                    <div key={index}>
                                        <h5>{item.title}</h5>
                                        {item.deskrip ? <span>{item.deskrip}</span> : null}
                                    </div>
                                ))
                            }
                        </div>
                        <div className="table__body">
                            <div className="table__column">
                                {
                                    tbThird.body.first_column.map((item, index)=> (
                                        <h4 key={index}>{item}</h4>
                                    ))
                                }
                            </div>
                            <div className="table__column">
                                {
                                    tbThird.body.second_column.map((item, index)=> (
                                        <h4 key={index}>{item}</h4>
                                    ))
                                }
                            </div>
                            <div className="table__column">
                                {
                                    tbThird.body.third_column.map((item, index)=> (
                                        <h4 key={index}>{item}</h4>
                                    ))
                                }
                            </div>
                            <div className="table__column">
                                {
                                    tbThird.body.fourth_column.map((item, index)=> (
                                        <h4 key={index}>{item}</h4>
                                    ))
                                }
                            </div>
                        </div>
                        <div className="table__row-cargo">
                            <div className="repair__camera">
                                <h6>
                                    {tbThird.cargo.camera_repair.title}
                                </h6>
                                <h6>
                                    {tbThird.cargo.camera_repair.price}
                                </h6>
                                <span>
                                   {tbThird.cargo.camera_repair.descript}
                                </span>
                            </div>
                            <div className="repair__tires">
                                <h6 className="title">
                                    {tbThird.cargo.cargo_title}
                                </h6>
                                <div className="tires__body">
                                    <div className="tires__half">
                                        {
                                            tbThird.cargo.cargo_body.first_part.map((item, index)=> (
                                                <div key={index} className="tires__patch">
                                                    <h6>
                                                        {item.title}
                                                    </h6>
                                                    <h6>
                                                        {item.price}
                                                    </h6>
                                                </div>
                                            ))
                                        }
                                    </div>
                                    <div className="tires__half">
                                        {
                                            tbThird.cargo.cargo_body.second_part.map((item, index)=> (
                                                <div key={index} className="tires__patch">
                                                    <h6>
                                                        {item.title}
                                                    </h6>
                                                    <h6>
                                                        {item.price}
                                                    </h6>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
                break;
            case 1:
                return (
                    <div className='table slide-top table-light_weight'>
                        <div className="table__header">
                            {
                                tbSec.headerFirst.map((item, index)=> (
                                    <div key={index}>
                                        <h5>{item.title}</h5>
                                        {item.deskrip ? <span>{item.deskrip}</span> : null}
                                    </div>
                                ))
                            }
                        </div>
                        <div className="table__body">
                            {
                                tbSec.bodyFirst.map((item,index)=> (
                                   <h4 key={index}>{item}</h4>
                                ))
                            }
                        </div>
                        <div className="table__header table__header-second">
                            {
                                tbSec.headerSecond.map((item, index)=> (
                                    <div key={index}>
                                        <h5>{item.title}</h5>
                                        {item.deskrip ? <span>{item.deskrip}</span> : null}
                                    </div>
                                ))
                            }
                        </div>
                        <div className="table__body table__body-second">
                            {
                                tbSec.bodySecond.map((item,index) => (
                                    <div key={index} className="table__body-el">
                                        {
                                            !item.multi ?
                                                <h4>
                                                    {item.title}
                                                </h4> :
                                             item.multi.map((item,index)=> (
                                                 <div key={index}>
                                                     <h4>
                                                         {item.title}
                                                     </h4>
                                                     <span>
                                                        {item.descript}
                                                    </span>
                                                 </div>
                                             ))
                                        }
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                )
                break;
            case 3:
                return (
                    <div className="table slide-top table-other">
                        <div className="table__header">
                            {
                                tbFourth.header.map((item,index) => (
                                    <h6 key={index}>{item}</h6>
                                ))
                            }
                        </div>
                        <div className="table__body tb-body-one">
                            {
                                tbFourth.body.map((item,index) => (
                                    <div key={index}>
                                        {item.title ? <h6>{item.title}</h6> : null}
                                        {item.descript ? <span>{item.descript}</span> : null}
                                        <h5>
                                            {item.price}
                                        </h5>
                                    </div>
                                ))
                            }
                        </div>
                        <div className="table__header tb-sec-header">
                            {tbFourth.header_second.map((item, index) => (
                                <h6 key={index}>{item}</h6>
                            ))}
                        </div>
                        <div className="table__body tb-body-sec">
                            <div className="tb-sec-div">
                                <span>
                                    {tbFourth.body_second.first_part.descript}
                                </span>
                                <h5>
                                    {tbFourth.body_second.first_part.price}
                                </h5>
                            </div>
                            <div className="tb-cord">
                                {
                                    tbFourth.body_second.second_part.map((item,index)=> (
                                        <div key={index} className="tb-cord-item">
                                            <div>
                                                <h5>
                                                    {item.text.title}
                                                </h5>
                                                <span>
                                                   {item.text.descript}
                                                 </span>
                                            </div>
                                            <div>
                                                <h5>
                                                    {item.price}
                                                </h5>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                        <h6 className="descr-other">
                            {tbFourth.cord_text}
                        </h6>
                    </div>
                )
                break;

        }
    }
    return (
        <li>
            <img src={StaticPath(`${img}.png`)} alt="" onClick={() => handleTableOpen(id)}/>
            {
                opened ?
                    tableType()
                    :
                    null
            }
        </li>
    )
}