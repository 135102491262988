import React, {useState} from 'react';

const New = ({img, title, text, date}) => {
    const [descriptionOpened, setDescriptionOpened] = useState(false);
    return (
        <div className="new">
            <div>
                <img src={"http://dev.advafert.ru/"+img} className="new__image" alt=""/>
            </div>

            <div className="new__text">
                <div className="new__title">
                    {title}
                </div>
                <div className="new__text_block">
                    <div className={`new__description ${descriptionOpened ? "new__description-opened" : null}`}>
                        {text}
                    </div>
                    <div onClick={() => setDescriptionOpened(!descriptionOpened)} className="new__open">
                        {descriptionOpened ? "свернуть" : "развернуть"}
                    </div>
                    <div className="new__date">
                        {date}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default New;