import {memo, useEffect, useMemo, useState} from "react";
import API from "../../http/requests";

const ModalRoulette =  ({modalText, modalDisable, title, rData, itemPrize})  =>{

    const [isEmptyDrop] = useState(rData[itemPrize].empty)

    return (
        <div className="modalRoulette" onClick={modalDisable}>
            <div className="modalRoulette__body">
                <div className="modalRoulette__content slide-top">
                    <div className="modalRoulette__wrapper">
                        {
                            isEmptyDrop ?
                            <h2>{title}</h2>
                            :
                            <div>
                                <h2>
                                    {modalText.p_text}
                                </h2>
                                <h1 style={{marginTop: 15}}>
                                    {rData[itemPrize]?.title}
                                </h1>
                                <h2 style={{marginTop: 15}}>
                                    Промокод: {rData[itemPrize]?.promo}
                                </h2>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}
export default memo(ModalRoulette);