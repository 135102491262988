import React from 'react';
import {Route, Routes} from "react-router-dom";


const AppRoutes = ({Profile, MainPage, News}) => {
    return (
        <Routes>
            <Route path={'/profile'} element={<Profile/>}/>
            <Route path={'/new'} element={<News/>}/>
            <Route path={'/'} element={<MainPage/>} exact/>
        </Routes>
    );
};

export default React.memo(AppRoutes);