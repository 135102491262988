import './Styles.scss'
export default function Preloader () {
    return (
        <div className="preloader__wrapper">
            <div className="preloader__body">
                <div className="preloader">

                </div>
            </div>
        </div>
    )
}