import React from 'react';
import ProfileDataItem from "../components/ProfileDataItem";

const EditWrapper = ({children, handleModal}) => {
    return (
        <div className="profile-content__edit" onClick={handleModal} >
            {children}
        </div>
    );
};

export default EditWrapper;