const diffDays = (date, otherDate) => Math.ceil(Math.abs(date - otherDate) / (1000 * 60 * 60 * 24));

export function diff_hours(dt2, dt1 = new Date()) {
    const days_diff = diffDays(dt2, dt1) - 1
    //console.log(days_diff, 'd')
    var diff = (dt2.getTime() - dt1.getTime()) / 1000;
    diff /= (60 * 60);
    const days = (diff/24)
    const hours = diff%24;
    return [~~days, Math.abs(Math.round(hours))];
}